import ContentHero from "@components/ContentHero"
import { PageProps } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import React, { FC, useEffect } from "react"

export interface ThankYouForReviewProps extends PageProps {}

export const ThankYouForReview: FC<ThankYouForReviewProps> = ({ navigate }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(`/`)
    }, 10000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <ContentHero
        title={
          <FormattedMessage
            defaultMessage="Thank you!"
            id="pages.ThankYouForReview.title"
          />
        }
        description={
          <div>
            <FormattedMessage
              defaultMessage="Your review will be shown in a day or two"
              id="pages.ThankYouForReview.message"
            />
            <br />
            <FormattedMessage
              defaultMessage="You will be automatically redirected in about 10 seconds"
              id="pages.ThankYouForReview.redirectMessage"
            />
          </div>
        }
      />
    </>
  )
}

export default ThankYouForReview
